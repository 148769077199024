.team-listitem {
  float: left;
  width: 300px;
  height: 30px;
  display: block;
  border-bottom: 1px solid grey;
  margin: 0.2em;
  padding: 5px;
  font-weight: 600;
  overflow: hidden;
}

.team-list {
    max-width: 700px;
    display: block;
}