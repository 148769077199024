/* Game Header */
.game-header {
  border-bottom: 1px solid black;
  padding: 1em;
  padding-bottom: 0.2em;
}
.game-header h2 {
  margin: 0px;
}

.game-link {
  color: white;
}
.date
{
  border-radius: 0.2em;
  float: left;
  height: 58px;
  width: 52px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-top: 0px;
  line-height: normal;
  border: 1px solid black;
  background-color: white;
}
.date .month
{
  background: #444;
  display: block;
  text-align: center;
  color: #FFF;
  font-size: 11px;
  padding-top: 4px;
  text-transform: uppercase;
}
.date .day
{
  display: block;
  text-align: center;
  padding-top: 5px;
  color: #222;
  font-size: 18px;
  font-weight: bold;
}
.date .year
{
  display: block;
  text-align: center;
  color: #222;
  font-size: 11px;
  font-weight: bold;
}

/* Buttons */
.btn-primary, .btn-primary:active, .btn-primary:visited {
  background-color: #ff6500 !important;
  border: #ba4900 1px solid;
}
.btn-primary:hover {
  background-color: #ba4900 !important;
  border: #ba4900 1px solid;
}


/* Nav bar */
.game-navbar {
  width: 100%;
  min-height: 35px;
  background: #ff6500;
  color: black;
  padding-left: 50px;
}

.game-navbar-title {
  font-weight: 500;
  font-size: 1.7em;
  color: white;
}

/* Buttons */
.scorez-button {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
  padding: 0.4em;
}

/* Menu */
#outer-container {
  height: 100%;
}

#page-wrap {
  padding-top: 0em;
  padding-left: 1.5em;
  padding-right: 3em;
}

.wrap-div {
  height: 100%;
}

.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 17px;
  left: 10px;
  top: 8px;
}
.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-item-list #scorez {
  color: #b8b7ad;
}
.bm-menu {
  background: #373a47;
  padding: 1.5em 1.0em 0;
  font-size: 1.15em;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #b8b7ad;
    margin: 1em 0;
    padding: 0;
}
.bm-menu a {
  margin-bottom: 1em;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
  text-decoration: none;
}
.bm-item-list a span {
  font-weight: 700;
}

@media only screen and (max-width: 480px) {
  .donate-text {
    font-size: 0.7em;
  }

  #page-wrap {
    padding: 0.2em;
  }
}