/*.game_settings {
  margin: 1em;
}

.game_settings td, .game_settings th {
  padding: 0.3em;
}*/
.settings-content
{
  padding: 0.2rem;
}

.ex-aequo-rule-container
{
  margin-bottom: 1rem;
}

.ex-aequo-rule
{
  display: inline-block;
  padding: 0.3em;
  border: 1px solid grey;
  margin-bottom: 0.2em;
  background-color: white;
  border-radius: 0.2rem;
}

.ex-aequo-rule-desc
{
  display: inline-block;
  font-weight: 700;
  width: 230px;
}

.ex-aequo-rule-desc span
{
  font-weight: 300;
  font-size: 12px;
}

.ex-aequo-rule-desc input
{
  margin: 0.2em;
}

.ex-aequo-label
{
  display: inline-block;
  width: 75px;
  font-weight: 300;
}

.tie-breaker-answer
{
  width: 70px;
}

.round-selection
{
  padding: 0.2rem;
  margin: 0.2rem;
}

.settings
{
  /* margin: 1rem; */
  /* background: #ffd8bf; */
  border: 1px solid grey;
  border-radius: 0.2rem;
  padding: 2rem;
  margin-bottom: 0.5rem;
}

.settings-input
{
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  .settings {
    padding: 0.2em;
  }
}