html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

a {
  color: #4e4a46;
  text-decoration: none;
}

#scorez {
  font-size: 1.8em;
  margin-bottom: 1em;
}

#root {
  height: 100%;
  width: 100%;
  background: #ffffff;
}

#main {
  height: 100%;
  /*background: linear-gradient(45deg, #ffffff, #ffdcc3, #ffffff);*/
  background: #ffffff;
}

.scorez-content {
  padding: 1em;
  max-width: 600px;
}
.game {
  width: 100%;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  border: 1px solid rgb(255, 255, 255);
  margin-bottom: 1rem;
  background-color: #ffe0cc;
  border-radius: 0.5rem;
}
.gamelink-name {
  font-weight: 700;
  font-size: 1.4rem;
}
.games {
  float: left;
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 1em;
}

.title-outer {
  padding: 0.5rem;
/*  border-radius: 1em;
  padding: 1em;*/
  background: #f3f3f3;
}
.title-logo {
  float: left;
  display: block;
  height: 2rem;
}
.title {
  float: left;
  display: block;
  height: 100%;
  font-weight: 500;
  font-size: 2rem;
  margin: 0px;
  margin-top: 0px;
  margin-left: 10px;
}
.donate {
  margin-top: 1em;
  padding-top: 1em;
  font-weight: 500;
  border-top: 1px solid black;
}
.donate-button {
  float: right;
}
.donate-text {
  float: right;
  font-size: 0.9em;
}

.language-select {
  float: right;
  display: block;
}
.language-select img {
  height: 15px;
}

.action-btn {
  background-color: #ffe0cc;
  border: 1px solid #ffc299;
}

@media only screen and (max-width: 480px) {
  .donate-text {
    font-size: 0.7em;
  }

}
.game-alert {
  margin: 1em;
}
.team-listitem {
  float: left;
  width: 300px;
  height: 30px;
  display: block;
  border-bottom: 1px solid grey;
  margin: 0.2em;
  padding: 5px;
  font-weight: 600;
  overflow: hidden;
}

.team-list {
    max-width: 700px;
    display: block;
}
/* GameSheet Table */
.gamesheet-container {
  text-align: center;
}
.gamesheet-table-container {
  display: inline-block;
  text-align: left;
  padding-top: 1em;
}
.gamesheet-table {
}
.gamesheet-view-row .gamesheet-row {
  padding: 0.2em;
}
.gamesheet-edit-row .gamesheet-row {
  padding: 1em;
}
.gamesheet-row {
  /*border: 1px #373a47 solid;*/
  background: #fff5ef;
}
.gamesheet-score {
  text-align: center;
}
.gamesheet-even-row {
  background: #ffe6d5;
}
.gamesheet-header {
  vertical-align: bottom;
  border: 1px #373a47 solid;
  background: transparent;
  color: #373a47;
  padding: 0em;
  border: 0px;
}
.gamesheet-teams-header {
  text-align: center;
}
.gamesheet-total {
  text-align: center;
  vertical-align: bottom;
}
.gamesheet-total-row, .gamesheet-index {
  font-weight: 600;
  min-width: 40px;
  text-align: center;
}
.gamesheet-tiebreaker {
  background: #c5bfbb;
}
.gamesheet-team {
  font-weight: 600;
}
.gamesheet-maxscore {
  font-weight: 900;
  color: ;
}

.sort-column-container {
  text-align: center;
}
.sort-column {
  font-size: 1.3em;
  cursor:pointer;
  display: inline-block;
}
.sort-column:hover {
  color: #ba4900;
}
.selected-sort-column {
  color: #ba4900;
}

.gamesheet-edit {
  padding: 0em;
  margin: 0em;
}
.gamesheet-input {
  /*padding: 1em;*/
  margin: 0em;
}

/*.gamesheet-button {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
  margin: 0.4em;
  padding: 0.4em;
}*/

.contextmenu-round-div {
  margin: 1em;
  -webkit-transform: rotate(315deg) translate(0px, 0px);
          transform: rotate(315deg) translate(0px, 0px);
  min-height: 20px;
  cursor:pointer;
}

.contextmenu-team-div {
  height: 100%;
  min-height: 20px;
  width: 100%;
  cursor:pointer;
  font-size: 0.8rem;
}

@media only screen and (max-width: 480px) {
  .gamesheet-row {
    padding: 0.5em;
  }
}
/* Scoreboard */
.scoreitem {
  position: absolute;
  font-weight: 700;
  height: 30px;
  width: 390px;
  font-size: 0.95rem;
  padding: 2px;
  z-index: 100;

  border-radius: 5px;
  border-bottom: 1px grey solid;
  background: -webkit-linear-gradient(315deg, #ffe0d350 0%, #e2ecff50 44%,#ffe0d350 100%);
  background: linear-gradient(135deg, #ffe0d350 0%, #e2ecff50 44%,#ffe0d350 100%);
}

.scoreboard-title {
  margin: 0px;
}
.scoreboard-title-tip {
  font-size: 11px;
}

/* Scoreboard settings table */
.scoreboard-settings td {
  padding: 8px;
}

.scoreboard-settings-container {
  padding: 30px;
}

.scoreboard-team {
  position: absolute;
  display: inline-block;
  z-index: 50;
}

.scoreitem-pos {
  position: relative;
  width: 30px;
  font-size: 0.95rem;
  color: #797979;
  z-index: 50;
  /*color: white;
  background-color: #797979;*/
  /*border: 1px dotted gray;*/
}

.scoreitem-team {
  position: relative;
  margin-left: 4px;
  height: 100%;
  vertical-align: center;
}

.scoreboard-medals {
  position: absolute;
  top: -5px;
  right: 55px;
  width: 300px;
  height: 50%;
  display: inline-block;
  color: #555555;
  text-align: right;
  opacity: 0;
  z-index: 100;
}

.scoreboard-delta {
  position: absolute;
  right: 35px;
  width: 20px;
  height: 100%;
  display: inline-block;
  color: #555555;
}

.scoreboard-change {
  position: absolute;
  top: 0px;
  display: inline-block;

  width: 100%;
  height: 10px;
}

.scoreboard-delta-score {
  position: absolute;
  top: 40%;
  display: inline-block;

  text-align: center;
  width: 100%;
  font-size: 10px;
}

.scoreboard-score {
  position: absolute;
  right: 3px;
  display: inline-block;
  text-align: right;
  width: 30px;
}

.scoreboard-change-up {
  background: url("/scorez/up.svg") no-repeat;
  background-size: contain;
  background-position: center;
}
.scoreboard-change-down {
  background: url("/scorez/down.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.scoreboard {
  margin-top: 10px;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.scoreboard-tooltip {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.scoreboard-tooltip-text {
  position: absolute;
  right: 0px;
  top: -20px;
  visibility: hidden;
  background-color: #393838;
  color: white;
  min-width: 200px;
  padding: 2px;
  border-radius: 5px;
  opacity: 0.95;
  z-index: 100;
}

.scoreboard-tooltip:hover .scoreboard-tooltip-text {
  position: absolute;
  visibility: visible;
  z-index: 100;
}

#bottom-scoreboard {
  height: 35px;
  position: fixed;
  bottom: 0%;
  width: 100px;
  background-color: #393838;
  opacity: 1;
  font-size: 0.8rem;
  color: white;
  text-align: center;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

  /* No select */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#bottom-scoreboard:after {
  height: 35px;
  width: 100px;
  content: "";
  background: rgb(255, 141, 75);
  display: block;
  bottom: 0%;
  position: fixed;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s
}

#bottom-scoreboard:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  -webkit-transition: 0s;
  transition: 0s
}

.scoreboard-scorez-advert {
  position: absolute;
  left: 35px;
  height: 17px;
  vertical-align: text-bottom;
  font-size: 12px;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-weight: 500;
  opacity: 0.5;

  /* No select */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.scoreboard-scorez-advert span {
  position: absolute;
  top: 2px;
  left: 17px;
}
.scoreboard-scorez-logo {
  height: 15px;
}

/*.badge-default {
  background: #636c72;
  color: #fff;
}
*/
@media only screen and (max-width: 480px) {
  .scoreitem {
    height: 25px;
    width: 85%;
    font-size: 0.8rem;
  }

  .scoreitem-pos {
    font-size: 0.8rem;
  }

  .scoreboard {
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .scoreitem {
    height: 25px;
    width: 85%;
    font-size: 0.6rem;
  }

  .scoreitem-pos {
    font-size: 0.6rem;
  }

  .scoreboard {
    font-size: 14px;
  }
}
/*.game_settings {
  margin: 1em;
}

.game_settings td, .game_settings th {
  padding: 0.3em;
}*/
.settings-content
{
  padding: 0.2rem;
}

.ex-aequo-rule-container
{
  margin-bottom: 1rem;
}

.ex-aequo-rule
{
  display: inline-block;
  padding: 0.3em;
  border: 1px solid grey;
  margin-bottom: 0.2em;
  background-color: white;
  border-radius: 0.2rem;
}

.ex-aequo-rule-desc
{
  display: inline-block;
  font-weight: 700;
  width: 230px;
}

.ex-aequo-rule-desc span
{
  font-weight: 300;
  font-size: 12px;
}

.ex-aequo-rule-desc input
{
  margin: 0.2em;
}

.ex-aequo-label
{
  display: inline-block;
  width: 75px;
  font-weight: 300;
}

.tie-breaker-answer
{
  width: 70px;
}

.round-selection
{
  padding: 0.2rem;
  margin: 0.2rem;
}

.settings
{
  /* margin: 1rem; */
  /* background: #ffd8bf; */
  border: 1px solid grey;
  border-radius: 0.2rem;
  padding: 2rem;
  margin-bottom: 0.5rem;
}

.settings-input
{
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  .settings {
    padding: 0.2em;
  }
}
/* Game Header */
.game-header {
  border-bottom: 1px solid black;
  padding: 1em;
  padding-bottom: 0.2em;
}
.game-header h2 {
  margin: 0px;
}

.game-link {
  color: white;
}
.date
{
  border-radius: 0.2em;
  float: left;
  height: 58px;
  width: 52px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-top: 0px;
  line-height: normal;
  border: 1px solid black;
  background-color: white;
}
.date .month
{
  background: #444;
  display: block;
  text-align: center;
  color: #FFF;
  font-size: 11px;
  padding-top: 4px;
  text-transform: uppercase;
}
.date .day
{
  display: block;
  text-align: center;
  padding-top: 5px;
  color: #222;
  font-size: 18px;
  font-weight: bold;
}
.date .year
{
  display: block;
  text-align: center;
  color: #222;
  font-size: 11px;
  font-weight: bold;
}

/* Buttons */
.btn-primary, .btn-primary:active, .btn-primary:visited {
  background-color: #ff6500 !important;
  border: #ba4900 1px solid;
}
.btn-primary:hover {
  background-color: #ba4900 !important;
  border: #ba4900 1px solid;
}


/* Nav bar */
.game-navbar {
  width: 100%;
  min-height: 35px;
  background: #ff6500;
  color: black;
  padding-left: 50px;
}

.game-navbar-title {
  font-weight: 500;
  font-size: 1.7em;
  color: white;
}

/* Buttons */
.scorez-button {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
  padding: 0.4em;
}

/* Menu */
#outer-container {
  height: 100%;
}

#page-wrap {
  padding-top: 0em;
  padding-left: 1.5em;
  padding-right: 3em;
}

.wrap-div {
  height: 100%;
}

.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 17px;
  left: 10px;
  top: 8px;
}
.right .bm-burger-button {
  left: auto;
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-item-list #scorez {
  color: #b8b7ad;
}
.bm-menu {
  background: #373a47;
  padding: 1.5em 1.0em 0;
  font-size: 1.15em;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #b8b7ad;
    margin: 1em 0;
    padding: 0;
}
.bm-menu a {
  margin-bottom: 1em;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
  text-decoration: none;
}
.bm-item-list a span {
  font-weight: 700;
}

@media only screen and (max-width: 480px) {
  .donate-text {
    font-size: 0.7em;
  }

  #page-wrap {
    padding: 0.2em;
  }
}
.login, .logout {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
}

.logged_in {
  text-align: right;
}

.logged_in>button, .logged_out>button{
  float: right;
  display: block;
}
#nav-profile {
    font-size: 1rem;
    display: block;
    float: right;
}
.create_game {
    margin: 1em;
}

.create_game td, .create_game th {
    padding: 0.3em;
}

.create_game input {
    border: 1px solid lightgrey;
    padding: 0.5em;
}

.create_game_button {
    padding: 0.4em;
    width: 100%;

    border-radius: 0.5em;
    background: #ff6500;
    color: white;
}
.date_picker {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
