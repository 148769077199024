*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #4e4a46;
  text-decoration: none;
}

#scorez {
  font-size: 1.8em;
  margin-bottom: 1em;
}

#root {
  height: 100%;
  width: 100%;
  background: #ffffff;
}

#main {
  height: 100%;
  /*background: linear-gradient(45deg, #ffffff, #ffdcc3, #ffffff);*/
  background: #ffffff;
}

.scorez-content {
  padding: 1em;
  max-width: 600px;
}
.game {
  width: 100%;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  border: 1px solid rgb(255, 255, 255);
  margin-bottom: 1rem;
  background-color: #ffe0cc;
  border-radius: 0.5rem;
}
.gamelink-name {
  font-weight: 700;
  font-size: 1.4rem;
}
.games {
  float: left;
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 1em;
}

.title-outer {
  padding: 0.5rem;
/*  border-radius: 1em;
  padding: 1em;*/
  background: #f3f3f3;
}
.title-logo {
  float: left;
  display: block;
  height: 2rem;
}
.title {
  float: left;
  display: block;
  height: 100%;
  font-weight: 500;
  font-size: 2rem;
  margin: 0px;
  margin-top: 0px;
  margin-left: 10px;
}
.donate {
  margin-top: 1em;
  padding-top: 1em;
  font-weight: 500;
  border-top: 1px solid black;
}
.donate-button {
  float: right;
}
.donate-text {
  float: right;
  font-size: 0.9em;
}

.language-select {
  float: right;
  display: block;
}
.language-select img {
  height: 15px;
}

.action-btn {
  background-color: #ffe0cc;
  border: 1px solid #ffc299;
}

@media only screen and (max-width: 480px) {
  .donate-text {
    font-size: 0.7em;
  }

}