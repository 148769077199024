/* GameSheet Table */
.gamesheet-container {
  text-align: center;
}
.gamesheet-table-container {
  display: inline-block;
  text-align: left;
  padding-top: 1em;
}
.gamesheet-table {
}
.gamesheet-view-row .gamesheet-row {
  padding: 0.2em;
}
.gamesheet-edit-row .gamesheet-row {
  padding: 1em;
}
.gamesheet-row {
  /*border: 1px #373a47 solid;*/
  background: #fff5ef;
}
.gamesheet-score {
  text-align: center;
}
.gamesheet-even-row {
  background: #ffe6d5;
}
.gamesheet-header {
  vertical-align: bottom;
  border: 1px #373a47 solid;
  background: transparent;
  color: #373a47;
  padding: 0em;
  border: 0px;
}
.gamesheet-teams-header {
  text-align: center;
}
.gamesheet-total {
  text-align: center;
  vertical-align: bottom;
}
.gamesheet-total-row, .gamesheet-index {
  font-weight: 600;
  min-width: 40px;
  text-align: center;
}
.gamesheet-tiebreaker {
  background: #c5bfbb;
}
.gamesheet-team {
  font-weight: 600;
}
.gamesheet-maxscore {
  font-weight: 900;
  color: ;
}

.sort-column-container {
  text-align: center;
}
.sort-column {
  font-size: 1.3em;
  cursor:pointer;
  display: inline-block;
}
.sort-column:hover {
  color: #ba4900;
}
.selected-sort-column {
  color: #ba4900;
}

.gamesheet-edit {
  padding: 0em;
  margin: 0em;
}
.gamesheet-input {
  /*padding: 1em;*/
  margin: 0em;
}

/*.gamesheet-button {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
  margin: 0.4em;
  padding: 0.4em;
}*/

.contextmenu-round-div {
  margin: 1em;
  transform: rotate(315deg) translate(0px, 0px);
  min-height: 20px;
  cursor:pointer;
}

.contextmenu-team-div {
  height: 100%;
  min-height: 20px;
  width: 100%;
  cursor:pointer;
  font-size: 0.8rem;
}

@media only screen and (max-width: 480px) {
  .gamesheet-row {
    padding: 0.5em;
  }
}