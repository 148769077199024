/* Scoreboard */
.scoreitem {
  position: absolute;
  font-weight: 700;
  height: 30px;
  width: 390px;
  font-size: 0.95rem;
  padding: 2px;
  z-index: 100;

  border-radius: 5px;
  border-bottom: 1px grey solid;
  background: linear-gradient(135deg, #ffe0d350 0%, #e2ecff50 44%,#ffe0d350 100%);
}

.scoreboard-title {
  margin: 0px;
}
.scoreboard-title-tip {
  font-size: 11px;
}

/* Scoreboard settings table */
.scoreboard-settings td {
  padding: 8px;
}

.scoreboard-settings-container {
  padding: 30px;
}

.scoreboard-team {
  position: absolute;
  display: inline-block;
  z-index: 50;
}

.scoreitem-pos {
  position: relative;
  width: 30px;
  font-size: 0.95rem;
  color: #797979;
  z-index: 50;
  /*color: white;
  background-color: #797979;*/
  /*border: 1px dotted gray;*/
}

.scoreitem-team {
  position: relative;
  margin-left: 4px;
  height: 100%;
  vertical-align: center;
}

.scoreboard-medals {
  position: absolute;
  top: -5px;
  right: 55px;
  width: 300px;
  height: 50%;
  display: inline-block;
  color: #555555;
  text-align: right;
  opacity: 0;
  z-index: 100;
}

.scoreboard-delta {
  position: absolute;
  right: 35px;
  width: 20px;
  height: 100%;
  display: inline-block;
  color: #555555;
}

.scoreboard-change {
  position: absolute;
  top: 0px;
  display: inline-block;

  width: 100%;
  height: 10px;
}

.scoreboard-delta-score {
  position: absolute;
  top: 40%;
  display: inline-block;

  text-align: center;
  width: 100%;
  font-size: 10px;
}

.scoreboard-score {
  position: absolute;
  right: 3px;
  display: inline-block;
  text-align: right;
  width: 30px;
}

.scoreboard-change-up {
  background: url("/scorez/up.svg") no-repeat;
  background-size: contain;
  background-position: center;
}
.scoreboard-change-down {
  background: url("/scorez/down.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.scoreboard {
  margin-top: 10px;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.scoreboard-tooltip {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.scoreboard-tooltip-text {
  position: absolute;
  right: 0px;
  top: -20px;
  visibility: hidden;
  background-color: #393838;
  color: white;
  min-width: 200px;
  padding: 2px;
  border-radius: 5px;
  opacity: 0.95;
  z-index: 100;
}

.scoreboard-tooltip:hover .scoreboard-tooltip-text {
  position: absolute;
  visibility: visible;
  z-index: 100;
}

#bottom-scoreboard {
  height: 35px;
  position: fixed;
  bottom: 0%;
  width: 100px;
  background-color: #393838;
  opacity: 1;
  font-size: 0.8rem;
  color: white;
  text-align: center;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

  /* No select */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#bottom-scoreboard:after {
  height: 35px;
  width: 100px;
  content: "";
  background: rgb(255, 141, 75);
  display: block;
  bottom: 0%;
  position: fixed;
  opacity: 0;
  transition: all 0.4s
}

#bottom-scoreboard:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.scoreboard-scorez-advert {
  position: absolute;
  left: 35px;
  height: 17px;
  vertical-align: text-bottom;
  font-size: 12px;
  font-variant: small-caps;
  font-weight: 500;
  opacity: 0.5;

  /* No select */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.scoreboard-scorez-advert span {
  position: absolute;
  top: 2px;
  left: 17px;
}
.scoreboard-scorez-logo {
  height: 15px;
}

/*.badge-default {
  background: #636c72;
  color: #fff;
}
*/
@media only screen and (max-width: 480px) {
  .scoreitem {
    height: 25px;
    width: 85%;
    font-size: 0.8rem;
  }

  .scoreitem-pos {
    font-size: 0.8rem;
  }

  .scoreboard {
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .scoreitem {
    height: 25px;
    width: 85%;
    font-size: 0.6rem;
  }

  .scoreitem-pos {
    font-size: 0.6rem;
  }

  .scoreboard {
    font-size: 14px;
  }
}