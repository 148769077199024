.login, .logout {
  border-radius: 0.5em;
  background: #ff6500;
  color: white;
}

.logged_in {
  text-align: right;
}

.logged_in>button, .logged_out>button{
  float: right;
  display: block;
}