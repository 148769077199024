.create_game {
    margin: 1em;
}

.create_game td, .create_game th {
    padding: 0.3em;
}

.create_game input {
    border: 1px solid lightgrey;
    padding: 0.5em;
}

.create_game_button {
    padding: 0.4em;
    width: 100%;

    border-radius: 0.5em;
    background: #ff6500;
    color: white;
}